'use client';
import React from 'react';
import style from './ContactUsBanner.module.scss';
import { cn } from '@/lib/utils';
import { Button } from '@/components/ui/button';
import image from '@/public/images/banners/keys.svg';
import Image from 'next/image';
import { useModalContext } from '@/context/ModalsContext';

interface Props {
  phone?: string;
}

export const ContactUsBanner = ({ phone }: Props) => {
  const { setIsModalOpened } = useModalContext();
  return (
    <div className={cn(style.wrapper, 'bg-textOrange')}>
      <div className={style.content}>
        <div>
          <p className="mb-2 text-left text-2xl font-bold text-white">
            Get in Touch with Tytum!
          </p>
          <div className={style.btnBlock}>
            {' '}
            <p className="text-left text-white">
              Need expert assistance or have a question? Our team is here to
              assist you. Fill out the form below, and we’ll get back to you
              promptly with the expert help you deserve.
            </p>
            <div>
              <Button
                onClick={() => setIsModalOpened(true)}
                className="bg-black px-5 py-6 text-lg  text-white"
              >
                Contact Us
              </Button>

              {phone && (
                <p className="mt-2">
                  <a
                    href="tel:+18582894881"
                    className="inline-flex items-center gap-2 px-5 py-2 text-2xl  hover:opacity-75"
                  >
                    <span>{phone}</span>
                  </a>
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className={style.imageWrapper}>
        <Image src={image} alt="keys" />
      </div>
    </div>
  );
};
