'use client';
import React from 'react';
import { motion, useInView } from 'framer-motion';
import { useRef } from 'react';
import style from './AboutUsSection.module.scss';
import Image from 'next/image';
import carImage from '@/public/images/home/car_about.webp';
import { Icons } from '@/components/icons';
import { Label } from '@/components/client/Label/Label';
import Button from '@/components/ui/Button/Button';
import { cn } from '@/lib/utils';
import { useModalContext } from '@/context/ModalsContext';

export const AboutUsSection = ({
  withoutHeader,
}: {
  withoutHeader?: boolean;
}) => {
  const { setIsModalOpened } = useModalContext();
  const ref = useRef(null);
  const isInView = useInView(ref, { once: false });

  return (
    <div className="py-5">
      {!withoutHeader && (
        <>
          <div className="mb-5 flex items-center justify-start">
            <Label label="About Us" />
          </div>
          <div className="mb-10 flex w-full flex-col items-start justify-between md:flex-row">
            <h2 className="mb-4 text-4xl font-bold md:mb-8">
              <p className="text-start font-semibold">
                <span className="textBlack font-semibold">Your Trusted</span>{' '}
                <span
                  style={{ color: '#fca12c' }}
                  className="textOrange font-semibold"
                >
                  Home
                </span>
              </p>
              <p className="text-start">
                <span
                  style={{ color: '#fca12c' }}
                  className="textOrange font-semibold"
                >
                  Services
                </span>{' '}
                <span className="textBlack font-semibold">Partner</span>
              </p>
            </h2>
            <p className="textGrey w-full max-w-[464px] text-left text-[18px] font-normal leading-[28px] md:w-2/4">
              Tytum team is known for top-quality services. With over 20 years
              of experience, we are dedicated to delivering excellent customer
              service.
            </p>
          </div>
          <div
            style={{ borderBottom: '1px solid #4f4f4f', opacity: '30%' }}
          ></div>
        </>
      )}
      <div className="flex items-center justify-center">
        <div className="container mx-auto px-4 py-10">
          <div className="flex flex-col items-center md:flex-row md:items-start">
            <div className="relative mb-6 w-full md:mb-0 md:w-1/2">
              <Image
                src={carImage}
                alt="Company Van"
                width={600}
                height={400}
                className="z-10 rounded-lg md:absolute"
              />
              <div className={cn(style.borderedBlock, 'hidden md:block')}></div>
              <div className={cn(style.banner, 'hidden md:absolute md:block')}>
                <div className={cn(style.bannerInner, 'hidden md:block')}>
                  <div className="flex flex-col justify-between">
                    <p className="text-left text-[28px] font-semibold leading-[54px]">
                      Always Here to Help
                    </p>
                    <div className="mt-14">
                      <p className="text-left">We&apos;re here </p>
                      <p className="text-left">whenever you need us!</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <motion.div
              ref={ref}
              initial={{ opacity: 0, y: 50 }}
              animate={isInView ? { opacity: 1, y: 0 } : {}}
              transition={{ duration: 0.5 }}
              className="w-full space-y-6 md:w-1/2 md:pl-8"
            >
              <div className="flex gap-4">
                <div>
                  <Icons.ourMissionIcon />
                </div>
                <div>
                  <h3 className="mb-2 text-start text-2xl font-semibold">
                    Our Mission
                  </h3>
                  <p className="textGrey text-start text-xs font-normal leading-6">
                    At TYTUM, we provide reliable, affordable services to the
                    San Diego community. We treat our customers with honesty and
                    respect, committed to exceptional service every time.
                  </p>
                </div>
              </div>

              <div className="flex gap-4">
                <div>
                  <Icons.outServiceIcon />
                </div>
                <div>
                  <h3 className="mb-2 text-start text-2xl font-semibold">
                    Our Services
                  </h3>
                  <p className="textGrey text-start text-xs font-normal leading-6">
                    We offer a range of services for residential and commercial
                    properties, including HVAC installation, repair, and
                    maintenance, electrical and plumbing services, construction
                    and remodeling, and real estate.
                  </p>
                </div>
              </div>

              <div className="flex gap-4 ">
                <div>
                  <Icons.ourGuarantee />
                </div>
                <div>
                  <h3 className="mb-2 text-start text-2xl font-semibold">
                    Our Guarantee
                  </h3>
                  <p className="textGrey text-start text-xs font-normal leading-6">
                    We stand behind our work and offer a satisfaction guarantee
                    on all of our services. If you are not completely satisfied
                    with our work, we will make it right.
                  </p>
                </div>
              </div>
              {!withoutHeader && (
                <div className="flex justify-start ">
                  <Button onClick={() => setIsModalOpened(true)} variant="main">
                    <p className="flex items-center justify-between ">
                      <span>Book a Service</span> <span>{<Icons.info />}</span>
                    </p>
                  </Button>
                </div>
              )}
            </motion.div>
          </div>
        </div>
      </div>
    </div>
  );
};
