'use client';

import React, {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState,
} from 'react';

interface NewsletterModalContextProps {
  isModalOpened: boolean;
  setIsModalOpened: Dispatch<SetStateAction<boolean>>;

  isModalCallsOpen: boolean;
  setisModalCallsOpen: Dispatch<SetStateAction<boolean>>;

  isModalQuoteOpen: boolean;
  setisModalQuoteOpen: Dispatch<SetStateAction<boolean>>;
}

export const ModalsContext = createContext<NewsletterModalContextProps | null>(
  null
);

function ModalsContextProvider<T>({ children }: PropsWithChildren<T>) {
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [isModalCallsOpen, setisModalCallsOpen] = useState(false);
  const [isModalQuoteOpen, setisModalQuoteOpen] = useState(false);

  return (
    <ModalsContext.Provider
      value={{
        isModalOpened,
        setIsModalOpened,
        isModalCallsOpen,
        setisModalCallsOpen,
        isModalQuoteOpen,
        setisModalQuoteOpen,
      }}
    >
      {children}
    </ModalsContext.Provider>
  );
}

export default ModalsContextProvider;

export function useModalContext() {
  const context = useContext(ModalsContext);
  if (!context) {
    throw new Error(
      'letterModalContext can only be used insideModalContextProvider'
    );
  }
  return context;
}
